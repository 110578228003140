import React from 'react'

const Notice=()=> {
    return (
        <React.Fragment>
            <section className="py-5 my-5"></section>
            <h2 className="text-center text-uppercase display-2">notice board</h2>
            <section className="py-3">
                <div className="container">
                    <div className="row">
                        <table class="table table-striped table-hover table-bordered table-bordered-right"
                            style={{border: "3px solid white"}}
                        >
                        <thead>
                                <tr className="text-light noticeWhite" style={{border: "3px solid red"}}>
                                    <th scope="col" className="text-center text-uppercase  pb-4" style={{border: "3px solid white"}}>Date</th>
                                    <th scope="col" className="text-center text-uppercase  pb-4" style={{border: "3px solid white"}}>Ref. No.</th>
                                    <th colspan="2" className="text-center text-uppercase pb-4" style={{border: "3px solid white"}}>Description</th>
                                    <th scope="col" className="text-center text-uppercase" style={{border: "3px solid white"}}>Download Link</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr className="text-white noticeWhite" style={{border: "3px solid white"}}>
                                    <td style={{border: "3px solid white"}}>23/09/2021</td>
                                    <td style={{border: "3px solid white"}}>001/21</td>
                                    <td colspan="2" style={{border: "3px solid white"}}>Admission going on at Maseer Institute for the following courses: (1) Arabic Language & Quranic Grammar -1st Semester (2) Advanced Quran Learning Program -Sura Baqara -Sura Nisa (3) Hifzul Quran (4) Quran Reading & Recitaion Program. Classes for new batches will begin within 1st week of October, 2021 In sha Allah.</td>
                                    <td style={{border: "3px solid white"}}>---</td>
                                </tr>
                                <tr className="text-white noticeWhite" style={{border: "3px solid white"}}>
                                    <td style={{border: "3px solid white"}}>23/09/2021</td>
                                    <td style={{border: "3px solid white"}}>information</td>
                                    <td colspan="2" style={{border: "3px solid white"}}>Using color to add meaning only provides a visual indication, which will not be conveyed to users of assistive technologies – such as screen readers. Ensure that information denoted by the color is either obvious from the content itself (e.g. the visible text), or is included through alternative means, such as additional text hidden </td>
                                    <td style={{border: "3px solid white"}}>@mdo</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                    {/* <div className="row">
                        <div className="col-2 text-center">1</div>
                        <div className="col-2 text-center">2</div>
                        <div className="col-6 text-center">3</div>
                        <div className="col-2 text-center">4</div>
                    </div> */}
                </div>
            </section>
        </React.Fragment>
    )
}
export default Notice;