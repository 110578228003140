import React, { useState } from "react";
import { DatePicker } from "react-rainbow-components";

export default function RainbowDatepicker() {
  const [date, setDate] = useState(null);

  function onChange(date) {
    setDate(date);
  }

  return (
    <div>
        <DatePicker
        id="datePicker-1"
        value={date}
        onChange={onChange}
        name="date"
    />
    </div>
  );
}

// import React, { Component } from "react";

// import DatePicker from "react-datepicker";
// import "react-datepicker/dist/react-datepicker.css";

// class Datepicker extends Component {

//     state = {
//         startDate: new Date()
//     };

//     handleChange = date => {
//         this.setState({
//             startDate: date
//         });
//     };

//     render() {

//         return (
//             <div>
//                 <DatePicker
//                     selected={this.state.startDate}
//                     onChange={this.handleChange}
//                     className="form-control"
//                     required
//                     name="date"
//                     id="date"
//                 />
//             </div>
//         )
//     };
// }

// export default Datepicker;