const CourseData=[
    {
        title: "Arabic Language & Quranic Grammar 1st Semester ",
        cOutLine: "Course Outcomes:",
        pOne: "-Preliminary reading, writing, listening & speaking skills development",
        pTwo: "-Approximately 300 vocabulary",
        pThree: "-Basic sentence construction without harakat",
        pFour: "-Habituate with grammar avoiding grammatical explanations",
        pFive: "",
        pSix: "",
        pSeven: "",
        pEight: "",
        pNine: "",
        pTen: "",
    },
    {
        title: "Arabic Language & Quranic Grammar 2nd Semester ",
        cOutLine: "Course Outcomes:",
        pOne: "-Elementary reading, writing, listening & speaking skills development ",
        pTwo: "-Approximately 300 vocabulary",
        pThree: "-Construction of long sentences with a variety of forms without harakat ",
        pFour: "-Understand selective texts of regular recitation from Quran & Sunnah",
        pFive: "-Habituate with grammar avoiding grammatical explanations",
        pSix: "",
        pSeven: "",
        pEight: "",
        pNine: "",
        pTen: "",
    },
    {
        title: "Arabic Language & Quranic Grammar 3rd Semester ",
        cOutLine: "Course Outcomes:",
        pOne: "-Secondary reading, writing, listening & speaking skills development ",
        pTwo: "-Approximately 300 vocabulary",
        pThree: "-Construction of long sentences with a variety of forms without harakat (Advanced)",
        pFour: "-Integration of communicative Arabic grammar and fundamental Arabic grammar based on the book 'Nahu Meer' (Iqna’ud Dameer)",
        pFive: "-Understand approximately 80% of the scripts of Quran and Sunnah",
        pSix: "",
        pSeven: "",
        pEight: "",
        pNine: "",
        pTen: "",
    },
    {
        title: "Arabic Language & Quranic Grammar (Semesters 4th – 6th)",
        cOutLine: "Course Outcomes:",
        pOne: "-Advanced reading, writing, listening & speaking skills development ",
        pTwo: "-Approximately 900 vocabulary",
        pThree: "-Construction of long complex sentences with a variety of forms without harakat",
        pFour: "-Special reading skill i.e. reading the scripts of Hadith without harakat & understand 80%-90% of the scripts of Quran and Hadith",
        pFive: "-Obtaining grasp of the meaning by listening to Quran recitation & understanding Arabic lectures (standard Arabic)",
        pSix: "-Integration of communicative Arabic grammar and fundamental Arabic grammar based on the book 'Matan al Ajrumiyyah' ",
        pSeven: "",
        pEight: "",
        pNine: "",
        pTen: "",
    },
    {
        title: "Quran Reading & Recitation Program  ( Three Semesters ) ",
        cOutLine: "Course Outcomes:",
        pOne: "",
        pTwo: "-1st Semester: Knowledge of Quranic alphabets and Makhraj (pronunciation), spell out & read Quranic words, basic tajweed",
        pThree: "-2nd Semester: Tilawatul Quran (Chapters 30, 29) and essential tajweed",
        pFour: "-3rd Semester: Tilawatul Quran – Sura Baqara and Al-e-Imran with moderate tajweed",
        pFive: "",
        pSix: "",
        pSeven: "",
        pEight: "",
        pNine: "",
        pTen: "",
    },
    {
        title: "Hifzul Quran Program ",
        cOutLine: "Course Outcomes:",
        pOne: "-1st Level: Chapters 30 & 29, Sura Baqara (Ayatul Kursi & last three verses), Sura Al-e-Imran (verses 189-194), Sura Kahf (first ten verses), Sura Loqman (verses 33,34), Sura Hashr (last 3 verses) ",
        pTwo: "-2nd Level: Chapters 30 & 29, Sura Baqara (whole), Sura Al-e-Imran (whole)",
        pThree: "-3rd Level: Gradual memorization of whole Quran ",
        pFour: "",
        pFive: "",
        pSix: "",
        pSeven: "",
        pEight: "",
        pNine: "",
        pTen: "",
    },
    {
        title: "Fundamental Quranic Grammar 1st Semester ",
        cOutLine: "Course Outcomes:",
        pOne: "-Completely based on Quranic word and sentence structure",
        pTwo: "-100 – 150 Recurrent Quranic words",
        pThree: "-Completion of 10% – 15% (Preliminary level) of Fundamental Quranic Grammar",
        pFour: "-Understanding the meanings of Ayats (Preliminary level)",
        pFive: "-Analysis of practical implementation of newly studied rules in words & sentences from Quran",
        pSix: "",
        pSeven: "",
        pEight: "",
        pNine: "",
        pTen: "",
    },
    {
        title: "Fundamental Quranic Grammar 2nd semester",
        cOutLine: "Course Outcomes:",
        pOne: "-Completely based on Quranic word and sentence structure",
        pTwo: "-100 – 150 new Recurrent Quranic words",
        pThree: "-Completion of 30% – 35% (Elementary level) of Fundamental Quranic Grammar",
        pFour: "-Understanding the meanings of Ayats (Elementary level)",
        pFive: "-Analysis of practical implementation of newly studied rules in words & sentences from Quran",
        pSix: "",
        pSeven: "",
        pEight: "",
        pNine: "",
        pTen: "",
    },
    {
        title: "Fundamental Quraic Grammer 3rd semester",
        cOutLine: "Course Outcomes:",
        pOne: "-Completely based on Quranic word and sentence structure",
        pTwo: "-100 – 150 new Recurrent Quranic words",
        pThree: "-Completion of 60% – 75% (Secondary level) of Fundamental Quranic Grammar",
        pFour: "-Understanding the meanings of Ayats (Secondary level)",
        pFive: "-Analysis of practical implementation of newly studied rules in words & sentences from Quran ",
        pSix: "-Once completed, the application of those grammatical rules will be studied through reading 3 Sura(s) ; which will prepare a student to enroll Advanced Quran Learning Program at any stage of the program",
        pSeven: "",
        pEight: "",
        pNine: "",
        pTen: "",
    },
    {
        title: "Advanced Quran Learning Programs",
        cOutLine: "Course Outcomes:",
        pOne: "-Completely focused on in-depth knowledge of Quran",
        pTwo: "-Quranic Word meaning ",
        pThree: "-Whole Ayat meaning",
        pFour: "-Grammatical analysis of words & sentences",
        pFive: "-Tafseer",
        pSix: "-Basic morals & rulings of an Ayat",
        pSeven: "-The application of the grammatical rules will be studied through reading several Sura(s) such as Sura Baqara, Sura Al-e-Imran, Sura Nisa etc",
        pEight: "",
        pNine: "",
        pTen: "",
    },
];

export default CourseData;