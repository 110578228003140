import React from 'react'

function Faculty() {
    return (
        <div>
            <div className="container">
                <div className="row">
                    <h2 className="text-uppercase"> Our Honorable Faculties</h2>
                    <h3><u><b>Mahmudul Hasan:</b></u></h3>
                    <h4>Full time Faculty, Maseer Institute</h4>
                    <h4>Course Developer & Instructor</h4>
                    <div className="col-md-12">
                        <p className="lead" style={{textAlign:"justify"}}>
                            Brother Mahmudul Hasan has been working as a course
                            developer and instructor of Arabic Language and Quranic
                            Grammar since 2013. He has more than six thousand
                            hours of experience in teaching Arabic language and
                            Quranic grammar courses in various academic
                            institutions. He is a Hafizul Quran and he obtained his
                            B.A. & M.A. in Arabic Language & Islamic studies from
                            Darul Ihsan University.
                            <br />
                            <strong>Author:</strong> Arabic for children, My Key to The Quran, Different
                            textbooks for Advanced Quran Learning Program such as
                            – Sura Fatiha & Sura Baqara, Sura Al-e-Imran, Sura Nisa, Sura Hujurat, Sura Yaseen, Sura Hadeed, Sura Munafiqun, Sura Mulk.
                            Some other textbooks are under development.
                        </p>
                    </div>
                </div>
            
                <div className="row">
                    <h3><u><b>Zahirul Islam:</b></u></h3>
                    <h4>Part time Faculty, Maseer Institute</h4>
                    <h4>Instructor of Hifzul Quran & Tajweedul Quran</h4>
                    <div className="col-md-12">
                        <p className="lead" style={{textAlign:"justify"}}>
                            Brother Zahirul Islam has been a dedicated Hifzul Quran
                            and Tajweedul Quran teacher in different institutions
                            since 2012. He taught more than five thousand hours in
                            this field of education. He is a Hafizul Quran and he
                            obtained his B.A. in Da’wah & Islamic Studies from
                            Darul Ihsan University and M.A. in Islamic Studies from
                            Southeast University. He has the Ijaazat (chain of ‘sanad’)
                            in Riwayat Hafs (a mode of Quran recitation).
                        </p>
                    </div>
                </div>
            
                <div className="row">
                    <h3><u><b>Huzaifa:</b></u></h3>
                    <h4>Part time Faculty, Maseer Institute</h4>
                    <h4>Instructor of Hifzul Quran & Tajweedul Quran</h4>
                    <div className="col-md-12">
                        <p className="lead" style={{textAlign:"justify"}}>
                            Brother Huzaifa is a well-qualified Tajweedul Quran &
                            Hifzul Quran instructor with more than three hundred
                            hours of teaching experience. He is a Hafizul Quran and
                            he obtained his B. Sc. in Mechanical Engineering from
                            Ahsanullah University of Science & Technology. He has
                            the Ijaazat (chain of ‘sanad’) in Riwayat Hafs (a mode of
                            Quran recitation). He completed Takmeel (Dawra e
                            Hadith) from Jamia Arabia Khadimul Islam.
                            He has the experience of leading the Tarawih Prayer
                            congregation in Abu-Dhabi, the capital city of United
                            Arab Emirates.
                        </p>
                    </div>
                </div>
            
            </div>
        </div>
    )
}

export default Faculty
