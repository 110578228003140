import React from 'react'

function GoogleMaps() {

    const mapsStyle = {
        width: "100%",
        height: "450px",
        frameborder: "0",
        border: "0",
        allowfullscreen: "true",
    };

    return (
        <React.Fragment>
            <section className="my-0 py-0">
            <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d7300.817082955669!2d90.36497361822268!3d23.80406726532655!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3755c1852d1ad939%3A0x85570d356ebfbbed!2sMaseer%20Institute!5e0!3m2!1sen!2sbd!4v1626336429360!5m2!1sen!2sbd" width="600" height="450" style="border:0;" allowfullscreen="" loading="lazy" style={mapsStyle}></iframe>
            </section>

        </React.Fragment>
    )
}

export default GoogleMaps
