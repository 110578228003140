import React, { useEffect } from 'react';
import CardHome from './Card';
import CardLogic from './CardData/CardLogic';
import AOS from 'aos';
import AppSlide from '../components/slider/AppSlide';



const Home = () =>{

    useEffect(() =>{
        AOS.init();
    }, []);

    return (
        <React.Fragment>
            
            <AppSlide  />
            <CardHome />
            <CardLogic />
        </React.Fragment>
    )
}
export default Home;