import React, { useState } from 'react'
import emailjs from 'emailjs-com';
import LabelImportantIcon from '@material-ui/icons/LabelImportant';

const Registration =()=> {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [number, setNumber] = useState("");
  const [course, setCourse] = useState("");
  const [message, setMessage] = useState("");

  const handleChange = (event) => {
    event.target.name=="name" 
    ? setName(event.target.value)

    : event.target.name=="email" 
    ? setEmail(event.target.value)

    : event.target.name=="number" 
    ? setNumber(event.target.value)

    : event.target.name=="course" 
    ? setCourse(event.target.value)

    : event.target.name=="message" 
    ? setMessage(event.target.value)

    : console.log("error")
  };

    function sendEmail(e) {
    e.preventDefault();

    emailjs.sendForm(
      'service_d6m52k9', 
      'template_zczzt1h', 
      e.target, 
      'user_VzcsRLSCpX7RjGfWdZ0Nu'
      ).then((result) => {
          console.log(result.text);
          result.text ==="OK" ? alert("Ragistrtion has been submited successfully.") : alert("didnt work")
          setName("")
          setEmail("")
          setNumber("")
          setCourse("")
          setMessage("")
      }, (error) => {
          console.log(error.text);
          setName("")
          setEmail("")
          setNumber("")
          setCourse("")
          setMessage("")
      });
      
  }
    return (
        <div>
            <div className="row enrollment">
            <div className="col-md-12">
              <h2 className="text-center display-4">ENROLLMENT</h2>
              <p className="lead">Dear <strong>future student</strong>, please follow the steps below to be a part of <strong>Maseer</strong> team of knowledge seeker:</p>

              <p className="lead"> <LabelImportantIcon /> <u>Step 1 – Registration:</u> Fill the <strong>Registration Form</strong> below and click register now. Our representative will be in touch with you within 3 days by e-mail or phone to guide you through the admission process. He will provide you the Registration ID (to be filled in the ‘Admission form’).</p>

              <p className="lead"> <LabelImportantIcon /> <u>Step 2 – Admission:</u> Once you get the <strong>Registration ID</strong> from our representative through e-mail, fill the Admission Form below the registration form and click submit.</p>
            </div>
          </div>

          <div className="registration-section">
            <div className="row">
              <h2 className="text-center display-4">REGISTRATION FORM</h2>
              <form onSubmit={sendEmail} >
                <div className="row mb-3">

                  <div className="col-md-6 col-sm-12">
                    <label htmlFor="name" className="col-sm-12 col-form-label">Your Name</label>
                    <input
                    id="name"
                    name="name"
                    className="form-control"
                    placeholder="Type Your Name *" 
                    onChange={(e) => handleChange(e)}
                    value={name}
                    required 
                    />
                  </div>

                  <div className="col-md-6 col-sm-12">
                    <label htmlFor="email" className="col-sm-12 col-form-label">Your E-mail</label>
                    <input 
                    id="email"
                    type="email" 
                    name ="email" 
                    className="form-control" 
                    placeholder="example@domain.com *"
                    onChange={(e) => handleChange(e)}
                    value={email}
                    required 
                  />
                  </div>

                  <div className="col-md-6 col-sm-12">
                    <label htmlFor="number" className="col-sm-12 col-form-label">Cell Number</label>
                    <input 
                    id="number" 
                    type="phone" 
                    name="number" 
                    className="form-control" 
                    placeholder="+880 123 123 123 " 
                    pattern="[+]{1}[0-9]{11,14}"
                    onChange={(e) => handleChange(e)}
                    value={number}
                     required 
                     />
                  </div>

                  <div className="col-md-6 col-sm-12">
                    <label htmlFor="course" className="col-sm-12 col-form-label">Desired Course</label>
                    <select
                    id="course"
                    name="course" 
                    type=""
                    className="form-control" 
                    onChange={(e) => handleChange(e)}
                    value={course}
                    required
                    >
                      <option value="" disable>Select Course</option>
                      <option value="Arabic Language & Quranic Grammar">Arabic Language & Quranic Grammar</option>
                      <option value="Quran Reading & Reciting program">Quran Reading & Recitation Program</option>
                      <option value="Hifzul Quran Program">Hifzul Quran Program </option>
                      <option value="Fundamental Quraic Grammer">Fundamental Quraic Grammar</option>
                      <option value="Advanced Quran Learning Programme">Advanced Quran Learning Program</option>
                    </select>
                  </div>
                </div>
                <div className="row">
                  <div className="col-12 mb-3">
                    <div className="">
                      <label className="mb-1" for="Textarea" htmlFor="message">
                        If you have studied any Arabic Language related course(s) from any institution or privately please write about it/them in the box below -
                      </label>
                      <textarea 
                      id="message"
                      name="message" 
                      className="form-control" 
                      rows="5" 
                      placeholder="Leave A Message Here *" 
                      onChange={(e) => handleChange(e)}
                      value={message}
                       >
                      </textarea>
                    </div>
                  </div>
                </div>
                {/* <button type="submit" className="btn btn-success ml-3 float-right">Register</button>
                <button type="reset" className="btn btn-warning float-right">Cancel</button> */}
                <div className="row mt-3">
                    <div className="col-md-8 mx-auto">
                        <div class="d-flex justify-content-center">
                           <a href="/enrollments" type="reset" className="btn btn-danger btn-lg">Refresh</a>
                            {/* <button type="reset" className="btn btn-danger btn-lg">Refresh</button> */}
                            <button type="submit" className="btn btn-success btn-lg ml-3">Register</button>
                            </div>
                    </div>
                </div>
              </form>
            </div>
          </div>
          
        </div>
    )
}
export default Registration;