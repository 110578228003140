import React from 'react'
import { useState } from 'react';
import Datepicker from './AnimateCounter/Datepicker';
import App from "./AnimateCounter/App";
import KeyboardArrowRightIcon from '@material-ui/icons/KeyboardArrowRight';
import StarIcon from '@material-ui/icons/Star';
import UGuardian from './UGuardian';
import emailjs from 'emailjs-com';

function Addmission() {
    const [regid, setReg] = useState(""); 
    const [name, setName] = useState("");

    const [alqg, setAlqg] = useState(false); 
    const [fqg, setFqg] = useState(false);
    const [aqlp, setAqlp] = useState(false);
    const [hq, setHq] = useState(false);
    const [qrrp, setQrrp] = useState(false);

    const [gName, setGName] = useState("");
    const [fname, setFname] = useState("");
    const [mname, setMname] = useState("");
    const [proname, setProname] = useState("");

    const [cellNumber, setCellno] = useState("");
    const [email, setEmail] = useState("");
    const [address, setAddress] = useState("");
    
    let date=new Date().toLocaleDateString();
    let identities = ["NID", "Birth-ID", "Passport-No."];
    const[displayindentity, setIdentity] = useState("NID");

    let gidentities = ["N I D", "Passport No."];
    const[gdisplayindentity, setGIdentity] = useState("N I D");

    const [cName, setCName] = useState("");
    const [rName, setRName] = useState("");
    const [gidentity2, setGidentity2] = useState("");
    const [gcell, setGcell] = useState("");
    const [gEmail, setGEmail] = useState("");

    const [identity2, setIdentity2] = useState("");
    const [eduname, setEduname] = useState("");
    const [eduextra, setEduextra] = useState("");
    const [edudetails, setEdudetails] = useState("");
    const [eName, setEName] = useState("");
    const [eCellNo, setECellNo] = useState("");

    const [ok, setOk] = useState("");

    const handleChange = (event) => {
        event.target.name=="regid" 
        ? setReg(event.target.value)

        : event.target.name=="alqg" 
        ? setAlqg(event.target.value)

        : event.target.name=="fqg" 
        ? setAlqg(event.target.value)

        : event.target.name=="aqlp" 
        ? setFqg(event.target.value)

        : event.target.name=="hq" 
        ? setHq(event.target.value)

        : event.target.name=="qrrp" 
        ? setQrrp(event.target.value)

        : event.target.name=="name" 
        ? setName(event.target.value)

        : event.target.name=="gName" 
        ? setGName(event.target.value)

        : event.target.name=="fname" 
        ? setFname(event.target.value)

        : event.target.name=="mname" 
        ? setMname(event.target.value)

        : event.target.name=="proname" 
        ? setProname(event.target.value)

        : event.target.name=="cellNumber" 
        ? setCellno(event.target.value)

        : event.target.name=="email" 
        ? setEmail(event.target.value)

        : event.target.name=="address" 
        ? setAddress(event.target.value)

        //UGuardian
        : event.target.name=="cName" 
        ? setCName(event.target.value)

        : event.target.name=="rName" 
        ? setRName(event.target.value)

        : event.target.name=="gidentity2" 
        ? setGidentity2(event.target.value)

        : event.target.name=="gcell" 
        ? setGcell(event.target.value)

        : event.target.name=="gEmail" 
        ? setGEmail(event.target.value)

        : event.target.name=="identity2" 
        ? setIdentity2(event.target.value)

        : event.target.name=="eduname" 
        ? setEduname(event.target.value)

        : event.target.name=="eduextra" 
        ? setEduextra(event.target.value)

        : event.target.name=="edudetails" 
        ? setEdudetails(event.target.value)

        : event.target.name=="eName" 
        ? setEName(event.target.value)

        : event.target.name=="eCellNo" 
        ? setECellNo(event.target.value)

        : event.target.name=="ok" 
        ? setOk(event.target.value)

        : console.log("error")
    };
    function handleGameClick() {
        setAlqg(!alqg);
    }

    function handleGameClick2() {
        setFqg(!fqg);
    }
    function handleGameClick3() {
        setAqlp(!aqlp);
    }
    function handleGameClick4() {
        setHq(!hq);
    }
    function handleGameClick5() {
        setQrrp(!qrrp);
    }

    function sendEmail(e) {
        e.preventDefault();

        emailjs.sendForm(
        'service_d6m52k9', 
        'template_lvbhfsu', 
        e.target, 
        'user_VzcsRLSCpX7RjGfWdZ0Nu'
        ).then((result) => {
            console.log(result.text);
            result.text ==="OK" ? alert("Admission has been submited successfully.") : alert("didnt work")
            
            setReg("")
            setAlqg("")
            setFqg("")
            setAqlp("")
            setHq("")
            setQrrp("")
            setName("")
            setGName("")
            setFname("")
            setMname("")
            setProname("")

            setEmail("")
            setAddress("")
            setCellno("")

            // UGuardian
            setCName("")
            setRName("")
            setGidentity2("")
            setGcell("")
            setCName("")
            setGEmail("")

            setIdentity2("")
            setEduname("")
            setEduextra("")
            setEdudetails("")
            setEName("")
            setECellNo("")

            setOk("")
        
        }, (error) => {
            console.log(error.text);
        });
    }

  

    return (
        <div>
            <div className="addminssion-section">
            <form onSubmit={sendEmail}>
                {/* header section */}
                <div className="row">
                    <h2 className="text-center display-4">ADMISSION FORM</h2>

                    <div className="col-md-6">
                        <div className="float-left">
                            <label for="registration" className="form-label">Registration ID</label>
                            <input 
                            // Format: 123-45-678
                            onChange={(e) => handleChange(e)}
                            value={regid}
                            id="registration"
                            name="regid"
                            pattern="[0-9]{3}-[0-9]{2}-[0-9]{3}"
                            type="phone" className="form-control" placeholder="Registration ID *" required/>
                        </div>
                    </div>

                    <div class="col-md-6">
                        <div class="float-md-right float-sm-left">
                            <label for="date" class="form-label">Date</label>
                            {/* <Datepicker  /> */}
                            {/* <SubDate /> */}
                            <input type="text" id="date" className="form-control" placeholder={date} value={date} name="sub" readOnly={true} required />
                        </div>
                    </div>
                </div>
                {/* end header section */}
                {/* course section */}
                <div className="row course-section">
                    <h3 className="my-3">Select Course ( At least One) </h3>
                    <div className="col-md-6">
                        <div className="">
                            <input 
                            onClick={()=>handleGameClick(alqg)}
                            onChange={(e) => handleChange(e)}
                            value={alqg}
                            type="checkbox" class="form-check-input mr-3 mt-2" id="alqg" />
                            <label class="form-check-label lead" for="alqg">Arabic Language & Quranic Grammar (ALQG)</label>
                            <div className="mt-2">
                                {/* {
                                    alqg ? */}
                                    <select 
                                    // onChange={(e) => handleChange(e)}
                                    // value={alqg}
                                    disabled={!alqg} name="alqg"className="form-control mb-3" id="Select-Semestar" required>
                                        <option value="" disable>Select-Semestar</option>
                                        <option value="1st Semester">1st Semester</option>
                                        <option value="2nd Semester">2nd Semester</option>
                                        <option value="2nd Semester">3rd Semester</option>
                                        <option value="2nd Semester">4th Semester</option>
                                        <option value="3rd Semester">5th Semester</option>
                                        <option value="6th Semester">6th Semester</option>
                                    </select>
                                    {/* : null
                                } */}
                            </div>
                        </div>
                        <div className="my-3">
                            <input 
                            onClick={()=>handleGameClick2(fqg)}
                            value={fqg}
                            type="checkbox" class="form-check-input mr-3 mt-2" id="fqg" />
                            <label class="form-check-label lead" for="fqg">Fundamental Quranic Grammar</label>
                            <div className="mt-2">
                                {/* {
                                fqg ? */}
                                <select 
                                // onChange={(e) => handleChange(e)}
                                //     value={fqg}
                                disabled={!fqg} name="fqg" className="form-control mb-3" id="Select-Semestar" required>
                                    <option value="" disable>Select-Semestar</option>
                                    <option value="1st Semester">1st Semester</option>
                                    <option value="2nd Semester">2nd Semester</option>
                                    <option value="2nd Semester">3rd Semester</option>
                                </select>
                                {/* : null
                                } */}
                            </div>
                        </div>
                        <div>
                            <input 
                            onClick={()=>handleGameClick3(aqlp)}
                            value={aqlp}
                            type="checkbox" class="form-check-input mr-3 mt-2" id="aql" />
                            <label class="form-check-label lead" for="aql">Advanced Quran Learning Program (AQLP)</label>
                            <div className="mt-2">
                                {/* {
                                aqlp ? */}
                                <input
                                // onChange={(e) => handleChange(e)}
                                //     value={aqlp}
                                 disabled={!aqlp} name="sura" type="text" class="form-control" placeholder="SURA : " required/>
                                {/* : null
                                } */}
                            </div>
                        </div>
                    </div>
                    <div className="col-md-6">
                        <div className="">
                            <input 
                            onClick={()=>handleGameClick4(hq)}
                            value={hq}
                            type="checkbox" class="form-check-input mr-3 mt-2" id="hq" />
                            <label class="form-check-label lead" for="hq">Hifzul Quran</label>
                            <div className="mt-2">
                                {/* {
                                hq ? */}
                                <select 
                                // onChange={(e) => handleChange(e)}
                                //     value={hq}
                                disabled={!hq} name="quran" className="form-control mb-3" id="Select-Semestar" required>
                                    <option value="" disable>Select-Level</option>
                                    <option value="Level-One">Level One</option>
                                    <option value="Level-Two">Level Two</option>
                                    <option value="Level-Tree">Level Three</option>
                                </select>
                                {/* : null
                                } */}
                            </div>
                        </div>
                        <div className="my-3">
                            <input 
                            onClick={()=>handleGameClick5(qrrp)}
                            value={qrrp}
                            type="checkbox" className="form-check-input mr-3 mt-2" id="qrrp" />
                            <label class="form-check-label lead" for="qrrp">Quran Reading & Recitation Program</label>
                            <div className="mt-2">
                                {/* {
                                qrrp ? */}
                                <select 
                                // onChange={(e) => handleChange(e)}
                                //     value={qrrp}
                                disabled={!qrrp} name="reading" className="form-control mb-3" id="Select-Semestar" required>
                                    <option value="" disable>Select-Semestar</option>
                                    <option value="1st Semester">1st Semester</option>
                                    <option value="2nd Semester">2nd Semester</option>
                                    <option value="3rd Semester">3rd Semester</option>
                                </select>
                                {/* : null
                                } */}
                            </div>
                        </div>
                    </div>
                </div>
                {/* end course section */}
                {/* self section */}
                <div className="row">
                    <div className="col-md-6">
                        <div class="form-floating">
                            <label class="col-form-label" for="name">Applicant's Name</label>
                            <input
                            onChange={(e) => handleChange(e)}
                            value={name}
                            name="name"
                            type="text" 
                            id="name" class="form-control" placeholder="Type Your Full Name *" required/>
                        </div>
                    </div>
                    <div className="col-md-3">
                        <div class="form-floating">
                            <label class="col-form-label" for="bdate">Date of Birth</label>
                            <Datepicker id="bdate"/>
                        </div>
                    </div>
                    <div className="col-md-3">
                        <div className="form-floating">
                            <label class="col-form-label" for="gname">Gender</label>
                            <select 
                            onChange={(e) => handleChange(e)}
                            value={gName}
                            className="form-control" name="gName" id="gname" required>
                                <option value="" disable>Select Gender </option>
                                <option value="Male">Male</option>
                                <option value="Female">Female</option>
                                <option value="Female">Others</option>
                            </select>
                        </div>
                        
                    </div>
                </div>
                {/* end self section */}
                {/* parents section */}
                <div className="row">
                    <div className="col-md-4">
                        <label for="fName" className="col-form-label">Father’s Name</label>
                        <input 
                        onChange={(e) => handleChange(e)}
                        value={fname}
                        name="fname" type="text" className="form-control" id="fName" placeholder="Father’s Name *" required/>
                    </div>
                    <div className="col-md-4">
                        <label for="mName" className="col-form-label">Mother’s Name</label>
                        <input 
                        onChange={(e) => handleChange(e)}
                        value={mname}
                        name="mname" type="text" className="form-control" id="mName" placeholder="Mother’s Name *" required />
                    </div>
                    <div className="col-md-4">
                        <label for="pro" className="col-form-label">Profession</label>
                        <input 
                        onChange={(e) => handleChange(e)}
                        value={proname}
                        name="proname" type="text" className="form-control" id="pro" placeholder="Profession *" required />
                    </div>
                </div>
                {/* end parents section */}
                {/* identity section */}
                <div className="row">
                    <div className="col-md-6">
                        <div className="row">
                            {
                                identities.map((result, i)=>(
                                    <div className="col-sm-4">
                                        <div className="form-check mt-1">
                                            <div>
                                                <label className="form-check-label" htmlFor={result}>{result}</label>
                                                    <input 
                                                    id={result}
                                                    value={result} 
                                                    class="form-check-input" 
                                                    type="radio"
                                                    onChange={(e)=>setIdentity(e.target.value)}
                                                    checked={displayindentity===result}
                                                    name="identity" 
                                                    required 
                                                />
                                                {/* <p style={{color: "red"}}>{result}</p> */}
                                            </div>
                                        </div>
                                    </div>
                                ))
                            }
                            {/* <div class="form-floating mt-2">
                                <input type="text" class="form-control" placeholder={displayindentity} required />
                            </div> */}
                            <div class="input-group mt-2">
                                <input for="nibp" type="hidden" className="input-group-text" name="identity" placeholder={displayindentity} disabled />
                                <input
                                onChange={(e) => handleChange(e)}
                                value={identity2}
                                 id="nibp" type="text" className="form-control mt-sm-2 mt-md-0" placeholder="Enter ID" name="identity2" required />
                            </div>
                        </div>
                
                        <div className="col-md-12">
                            <label for="cellNo" className="col-form-label">Cell Number</label>
                            <input 
                            id="cellNo" 
                            name="cellNumber" 
                            onChange={(e) => handleChange(e)}
                            value={cellNumber}
                            type="phone" 
                            pattern="[+]{1}[0-9]{11,14}"
                            className="form-control" placeholder="+880 123 123 123 *" required />
                        </div>

                        <div className="col-md-12">
                            <label for="email" className="col-form-label">E-mail</label>
                            <input 
                            onChange={(e) => handleChange(e)}
                            value={email}
                            id="email" name="email" type="email" className="form-control" placeholder="example@domain.com *" required />
                        </div>

                        <div className="col-md-12">
                            <label for="address" className="col-form-label">Address</label>
                            <input 
                            onChange={(e) => handleChange(e)}
                            value={address}
                            name="address" type="text" className="form-control" id="address" placeholder="Type Your Complete Address *" required />
                        </div>
                    
                    </div>
                    <div className="col-md-6">
                        <div className="energency-section">
                            <div className="row">
                                <div className="col-md-6">
                                    <div className="">
                                    </div>
                                    <div className="">
                                        <label htmlFor="select-profession" className="col-form-label"><strong>Educational Qualification </strong></label>
                                        <select 
                                        onChange={(e) => handleChange(e)}
                                        value={eduname}
                                        className="form-control" name="eduname" id="select-profession" required>
                                            <option value="" disable>Educational Qualification </option>
                                            <option value="Primary/PSC/PDC">Primary/PSC/PDC</option>
                                            <option value="HighSchool/JSC/JDC">HighSchool/JSC/JDC</option>
                                            <option value="O'Levels/SSC/Dakhil">O'Levels/SSC/Dakhil</option>
                                            <option value="A'Levels/HSC/Alim">A'Levels/HSC/Alim</option>
                                            <option value="BA/BSc/BEd/Diploma/Degree">BA/BSc/BEd/Diploma/Degree</option>
                                            <option value="MA/MSc/MBBS/MEd">MA/MSc/MBBS/MEd</option>
                                            <option value="M.Phil/PhD">M.Phil/PhD</option>
                                            <option value="Others">Others</option>
                                        </select>
                                    </div>
                                </div>
                                <div className="col-md-6">
                                <div className="">
                                    <label for="eduextra" className="col-form-label">( If ‘othres’, please specify )</label>
                                    <input 
                                    onChange={(e) => handleChange(e)}
                                    value={eduextra}
                                    className="form-control"
                                    name="eduextra"
                                    id="eduextra"
                                    type="text" 
                                    placeholder="Others:" />
                                </div>
                            </div>
                            </div>
                            <div className="col-md-12">
                            <div className="">
                                <label for="eduDe" className="col-form-label"><strong>Details</strong> (If any, such as field of study)</label>
                                <input 
                                onChange={(e) => handleChange(e)}
                                value={edudetails}
                                className="form-control"
                                name="edudetails"
                                id="eduDe"
                                type="text" 
                                placeholder="Example : BSc in EEE / MA in English Literature" />
                            </div>
                        </div>
                        
                        </div>
                        
                        <div className="row">
                            <h4 className="text-center mt-1 pt-1">Emergency Contact</h4>
                            <div className="col-md-6">
                                <label for="eName" class="form-label">Name :</label>
                                <input 
                                onChange={(e) => handleChange(e)}
                                value={eName}
                                name="eName" type="text" class="form-control" id="eName" placeholder="Name *" required/>
                            </div>
                            <div className="col-md-6">
                                <label for="eCellNo" class="form-label">Cell Number</label>
                                <input 
                                onChange={(e) => handleChange(e)}
                                value={eCellNo}
                                id="eCellNo"
                                name="eCellNo" 
                                type="phone"
                                placeholder="+880 123 123 123 " 
                                pattern="[+]{1}[0-9]{11,14}"
                                 class="form-control"  required/>
                            </div>
                        </div>
                    </div>
                </div>
                {/* end identity section */}
                {/* Terms section */}
                <div className="row">
                    <div className="col-md-12">
                        <button type="button" className="btn btn-outline-warning my-3" data-toggle="collapse" data-target="#demoTerms">Terms & conditions: Click To View</button>
                    </div>
                    <div className="container collapse" id="demoTerms">
                        <p className="termsCondition text-justify">< StarIcon/>I hereby declare that the statements given in this form are true and I acknowledge that Maseer institute reserves the right
                            to verify the information given in this form and make decision about my studentship if there is any misleading
                            information.</p>
                        <p className="termsCondition">< StarIcon/>During my presence at Maseer (online/on-campus), I will maintain courtesy & code of conduct.</p>
                        <p className="termsCondition">< StarIcon/>I will not engage in any political discussion/activity within Maseer premises (online/on-campus).</p>
                        <p className="termsCondition text-justify">< StarIcon/>I will not take part in any discussion/activity against the rules or laws of Bangladesh whilst I am a student of Maseer
                            institute and I acknowledge that if I do so Maseer will not be responsible for my action.</p>
                        <p className="termsCondition">< StarIcon/>I will not engage in any argumentative discussion regarding laws of Islam within Maseer premises (online/on-campus).</p>
                        <p className="termsCondition text-justify">< StarIcon/>I acknowledge that reproduction and modification of any course material of Maseer institute under any circumstances, is
                            strictly prohibited without prior written permission from Maseer Institute.</p>
                        <p className="termsCondition">< StarIcon/>I will ensure regular payment of semester and other fees in time.
                        </p>
                        <p className="termsCondition">< StarIcon/>I acknowledge that Maseer institute reserves the right to make any course related changes such as class schedule, syllabus
                            etc.</p>
                    </div>
                    {/* <h4>Declaration: </h4>
                    <div className="">
                        <input className="form-check-input mr-2" type="checkbox" value="agree" id="terms" required />
                        <label className="form-check-label" for="terms">
                            I here by declare that I understand and agree with the 'Terms & Conditions' for admission at Maseer Institute.
                        </label>
                    </div> */}
                </div>
                {/* end Terms section */}
                {/* Guardian section */}
                <div className="row">
                    {/* <UGuardian /> */}
                    <div className="col-md-12">
                        <div type="" className="btn btn-block mt-3 mb-1" data-toggle="collapse" data-target="#demoGuardian" style={{backgroundColor: "#A17917"}}>
                            <h5 className="text-uppercase mt-2 text-white">If the applicant is under the age of 18 or being admitted by the guardian, Please <u> Click Here...</u></h5>
                            </div>
                        {/* <p className="lead text-center">(If the applicant is under the age of 18 or being admitted by the guardian)</p> */}
                    </div>
                </div>
                <div className="toogle-section collapse" id="demoGuardian">
                    <h3 className="text-center">Undertaking by the guardian</h3>
                    <div className="row">
                        <div className="col-md-6">
                            <div className="">
                                <label for="cName" className="col-form-label"><strong>Name of the guardian:</strong></label>
                                <input 
                                name="cName" 
                                type="text" 
                                onChange={(e) => handleChange(e)}
                                value={cName}
                                className="form-control" 
                                id="cName" placeholder="Name of the guardian:" />
                            </div>
                        </div>
                        <div className="col-md-6">
                            <div className="">
                                <label for="rName" className="col-form-label"><strong>Relation with the applicant: </strong> ( Example: Father, Mother etc.)</label>
                                <input 
                                name="rName" 
                                type="text" 
                                onChange={(e) => handleChange(e)}
                                value={rName}
                                className="form-control" 
                                id="rName" 
                                placeholder="Relation with the applicant:" />
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-6">
                            <div className="row">
                            {
                                gidentities.map((gresult, i)=>(
                                    <div className="col-sm-6 mt-1">
                                        <div className="form-check">
                                            <div>
                                                <label className="form-label" htmlFor={gresult}>{gresult}</label>
                                                <input 
                                                    id={gresult}
                                                    value={gresult} 
                                                    className="form-check-input" 
                                                    type="radio"
                                                    onChange={(e)=>setGIdentity(e.target.value)}
                                                    checked={gdisplayindentity===gresult}
                                                    name="gidentity" 
                                                />
                                                {/* <p style={{color: "red"}}>{result}</p> */}
                                            </div>
                                        </div>
                                    </div>
                                ))
                            }
                            {/* <div class="input-group mt-2">
                                <input for="nibp" className="input-group-text" name="identity" placeholder={displayindentity} disabled />
                                <input
                                onChange={(e) => handleChange(e)}
                                value={identity2}
                                 id="nibp" type="text" className="form-control" placeholder="Enter Identyty" name="identity2" required />
                            </div> */}
                                <div class="input-group mt-2">
                                    <input for="nibp" type="hidden" className="input-group-text" name="gidentity" placeholder={gdisplayindentity} disabled />
                                    <input
                                    onChange={(e) => handleChange(e)}
                                    value={gidentity2}
                                    id="nibp" type="text" className="form-control" placeholder="Enter ID" name="gidentity2" />
                                </div>
                            </div>
                        </div>
                        <div className="col-md-3">
                            <label for="gcell" className="col-form-label">Cell Number <small>( Format: +880 123 123)</small></label>
                            <input 
                                name="gcell"
                                type="phone" 
                                onChange={(e) => handleChange(e)}
                                value={gcell}
                                pattern="[+]{1}[0-9]{11,14}"
                                className="form-control" id="gcell" placeholder="Cell No. of the guardian:" />
                        </div>
                        <div className="col-md-3">
                            <label for="gemail" class="col-form-label">Email</label>
                            <input 
                            name="gEmail" 
                            type="email" 
                            onChange={(e) => handleChange(e)}
                            value={gEmail}
                            className="form-control" 
                            id="gemail" 
                            placeholder="example@domain.com" />
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-12 mt-3">
                            <p>I do understand & agree with the 'Terms & Conditions' for admission at Maseer Institute and I ensure that my ward will
                            refrain from violation of those ‘Terms & Conditions’. I do hereby undertake to ensure that I shall be responsible for the
                            educational needs of my ward during the period of his/her study at Maseer institute.
                            </p>
                        </div>
                        {/* <div class="form-check">
                            <div className="">
                                <input name="yes" className="form-check-input mr-2" type="checkbox" value="agree" id="terms" />
                                <label className="form-check-label" for="terms">
                                    I do hereby agree with the above statement
                                </label>
                            </div>
                        </div> */}
                    </div>
                </div>
              
                {/* end Guardian section */}
                {/* Submit Button section */}
                {/* <UGuardian /> */}
                <div className="row">
                    <div className="col-md-12 col-sm-12">
                        <h4 className="mt-3">Declaration: </h4>
                        <div class="form-check">
                            <div className="">
                                <input 
                                onChange={(e) => handleChange(e)}
                                value={ok}
                                name="ok" className="form-check-input mr-2" type="checkbox" id="terms" required/>
                                <label className="form-check-label" for="terms">
                                    I here by declare that I understand and agree with the 'Terms & Conditions' for admission at Maseer Institute.
                                    <br />
                                    I do hereby agree with the undertaking statement. (If the applicant is being admitted by the guardian)
                                </label>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="row mt-3">
                    <div className="col-md-8 mx-auto">
                        <div class="d-flex justify-content-center">
                            <a href="/enrollments" type="reset" className="btn btn-danger btn-lg">Refresh</a>
                            {/* <button type="reset" className="btn btn-danger btn-lg">Refresh Form</button> */}
                            <button type="submit" className="btn btn-success btn-lg ml-3" disabled={!(alqg || fqg || aqlp || hq || qrrp)}>Submit Form</button>
                            </div>
                    </div>
                </div>
            </form>

        </div>


        </div>
    )
}

export default Addmission
