import React from 'react';
import Button from '@material-ui/core/Button';
import Gallery from './Gallery';
import LabelImportantIcon from '@material-ui/icons/LabelImportant';
import Faculty from './Faculty';


const About = () =>{
    return (
        <React.Fragment>
            <section className="py-5 my-5"></section>
            <div className="container">
                <div className="row">
                    <div className="col-12">
                        <p className="lead">
                            <h2 >WHO ARE WE</h2>
                        Maseer is an academic institute that offers Quranic and Arabic language learning programs to the students of any educational background. Our programs are very flexible and versatile for beginner, primary, secondary or advanced level students. We provide our courses both on-campus and online.
                        <p> </p>
                        <p> </p>
                        <h2 className="">AIMS & OBJECTIVES</h2>
                            <p><LabelImportantIcon /> Encourage students to live, learn and conduct themselves according to the laws of the Holy Quran and Sunnah of the Prophet Muhammad (SAW), </p>
                            <p><LabelImportantIcon /> Provide adequate knowledge of Quranic and conversational Arabic.</p>
                            <p><LabelImportantIcon /> Educate students to read the Quran properly  and memorise (Hifz) easily.</p>
                            
                        <p></p>
                        <p></p>
                        <h2 className="">WHY SELECT MASEER</h2>
                            <p><LabelImportantIcon /> The number of muslims who get Islamic & Arabic knowledge in religious schools is very low. Maseer is the place to seek the knowledge of Arabic language based upon Quran and Sunnah while being involved in any occupation.</p>
                            <p><LabelImportantIcon /> We provide simplified courses which gives a beginner or a student of any level a quick learning ability.</p>
                            <p><LabelImportantIcon /> Most of our Arabic language teaching methods include practical implementation from Quran, Sunnah & conversational Arabic.</p>
                        </p>
                    </div>
                </div>
            </div>
            <section className="py-3 my-3">
                <Faculty />
            </section>
        </React.Fragment>
    )
}
export default About;