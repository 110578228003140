const CData = [
    {
        title: "Quran Learning Programs",
        imgSrc: "https://images.unsplash.com/photo-1586760523733-5a11738888aa?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=752&q=80",
        text: "Quran Reading and Recitation Program",
        text2: "Hifzul Quran",
    },
    {
        title: "Arabic Language Programs",
        imgSrc: "https://images.unsplash.com/photo-1590720419657-cb99915dd9f2?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=890&q=80",
        text: "Arabic Language and Quranic Grammar (6 Semesters)",
        text2: "Fundamental Quranic Grammar (3 Semesters)",
    },
    {
        title: "Anvanced Quran Learning Programs",
        imgSrc: "https://images.unsplash.com/photo-1559355324-a5879d536e84?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=809&q=80",
        text: "Semesters are divided according to Sura-s from the whole Quran",
        text2: "",
    }
];

export default CData;