import React from 'react'
import SlideShow from "./SlideShow";
import imageOne from "../img/Slide-1.png";
import imageTwo from "../img/Slide-2.png";
import imageThree from "../img/Slide-3.png";
import imageFour from "../img/Slide-4.png";
import imageFive from "../img/Slide-5.png";



// import imageOne from "../img/slide-one.jpg";
// import imageTwo from "../img/slide-two.jpg";
// import imageThree from "../img/slide-three.jpg";
// import imageFour from "../img/slide-four.jpg";
// import imageFive from "../img/slide-five.jpg";



export default function AppSlide() {
    return (
        <div>
            <div className="mt-5 pt-5">
                <div className="mt-3 pt-1 appSlide"></div>
            </div>
            <SlideShow
                autoPlay={true}
                activeSlideDuration={10000}
                interactionMode="swipe"
                alignCaption="center"
                alignIndicators="center"
                indicatorsColor="#fff"
                useRightLeftTriangles={true}
                rightTriangleColor="#fff"
                leftTriangleColor="#fff"
                rightIcon={
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 256 512">
                    <path d="M24.707 38.101L4.908 57.899c-4.686 4.686-4.686 12.284 0 16.971L185.607 256 4.908 437.13c-4.686 4.686-4.686 12.284 0 16.971L24.707 473.9c4.686 4.686 12.284 4.686 16.971 0l209.414-209.414c4.686-4.686 4.686-12.284 0-16.971L41.678 38.101c-4.687-4.687-12.285-4.687-16.971 0z" />
                    </svg>
                }
                leftIcon={
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 256 512">
                    <path d="M231.293 473.899l19.799-19.799c4.686-4.686 4.686-12.284 0-16.971L70.393 256 251.092 74.87c4.686-4.686 4.686-12.284 0-16.971L231.293 38.1c-4.686-4.686-12.284-4.686-16.971 0L4.908 247.515c-4.686 4.686-4.686 12.284 0 16.971L214.322 473.9c4.687 4.686 12.285 4.686 16.971-.001z" />
                    </svg>
                }
                >
                <div >
                    <img className="imageSlide" src={imageOne} alt="" />
                    <div className="carousel-caption">
                    <h3 className="carousel-caption-title text-uppercase h3One">learning arabic is the key to understand the holy quran and sunnah of prophet muhammad (SAW)</h3>
                    <p className="carousel-caption-subtitle"> </p>
                    </div>
                </div>
                <div >
                    <img className="imageSlide" src={imageTwo} alt="" />
                    <div className="carousel-caption">
                    <h3 className="carousel-caption-title  text-uppercase h3Two">Learn arabic and make the path easier to connect with native speakers</h3>
                    <p className="carousel-caption-subtitle"></p>
                    </div>
                </div>
                <div >
                    <img className="imageSlide" src={imageThree} alt="" />
                    <div className="carousel-caption">
                   
                    <h3 className="carousel-caption-subtitle h3Three pb-3"> قَالَ رَسُولُ اللهِِ ﷺ : مَنْ سَلَكَ طَرِيقًا يَلْتَمِسُ فِيهِ عِلْمًا سَهَّلَ اللَّهُ لَهُ بِهِ طَرِيقًا إِلَى الْجَنَّةِ </h3>
                    <p className="carousel-caption-title  text-uppercase p3Three">Rasool (SAW) said: “Whoever follows a path in the pursuit of knowledge Allah will make a path to Jannah easy for him” <br /> (Ibn Maajah)</p>
                    </div>
                </div>
                <div >
                    <img className="imageSlide" src={imageFour} alt="" />
                    <div className="carousel-caption">
                    <h3 className="carousel-caption-title h3Four pb-3"> الْعِلْمُ يُؤْتَى وَلَايَأْتِيْ </h3>
                    <p className="carousel-caption-subtitle text-uppercase p4Three">Knowledge doesn’t come but you have to pursue it - Imam Malik Ibn Anas</p>
                    </div>
                </div>
                <div >
                    <img className="imageSlide" src={imageFive} alt="" />
                    <div className="carousel-caption">
                    <h3 className="carousel-caption-title text-uppercase h3Five"> Learn Arabic grammar with the grammatical terms known to you </h3>
                    <p className="carousel-caption-subtitle"></p>
                    </div>
                </div>
            </SlideShow>
        </div>
    )
}
