import React, { useState } from 'react';
import emailjs from 'emailjs-com';
function Form() {
    const [alqg, setAlqg] = useState(false);
    const [fqg, setFqg] = useState(false);
    const [aqlp, setAqlp] = useState(false);
    const [hq, setHq] = useState(false);
    const [qrrp, setQrrp] = useState(false);
    let identities = ["NID", "Birth-ID", "Passport-No."];
    const[displayindentity, setIdentity] = useState("NID");

 function handleGameClick() {
    setAlqg(!alqg);
  }

  function handleGameClick2() {
    setFqg(!fqg);
  }
  function handleGameClick3() {
    setAqlp(!aqlp);
  }
  function handleGameClick4() {
    setHq(!hq);
  }
  function handleGameClick5() {
    setQrrp(!qrrp);
  }

    return (
        <div>
            <form action="">
                {/* course section */}
                <div className="row course-section">
                    <h3 className="my-3">Select Course ( At least One) </h3>
                    <div className="col-md-6">
                        <div className="">
                            <input 
                            onClick={()=>handleGameClick(alqg)}
                            value={alqg}
                            type="checkbox" class="form-check-input mr-3 mt-2" id="alqg" />
                            <label class="form-check-label lead" for="alqg">Arabic Language Quranic Grammar (ALQG)</label>
                            <div className="mt-2">
                                {/* {
                                    alqg ? */}
                                    <select disabled={!alqg} name="alqg"className="form-control mb-3" id="Select-Semestar" required>
                                        <option value="" disable>Select-Semestar</option>
                                        <option value="1st Semester">1st Semester</option>
                                        <option value="2nd Semester">2nd Semester</option>
                                        <option value="2nd Semester">3rd Semester</option>
                                        <option value="2nd Semester">4th Semester</option>
                                        <option value="3rd Semester">5th Semester</option>
                                        <option value="6th Semester">6th Semester</option>
                                    </select>
                                    {/* : null
                                } */}
                            </div>
                        </div>
                        <div className="my-3">
                            <input 
                            onClick={()=>handleGameClick2(fqg)}
                            value={fqg}
                            type="checkbox" class="form-check-input mr-3 mt-2" id="fqg" />
                            <label class="form-check-label lead" for="fqg">Fundamental Quranic Grammar</label>
                            <div className="mt-2">
                                {/* {
                                fqg ? */}
                                <select disabled={!fqg} name="learning" className="form-control mb-3" id="Select-Semestar" required>
                                    <option value="" disable>Select-Semestar</option>
                                    <option value="1st Semester">1st Semester</option>
                                    <option value="2nd Semester">2nd Semester</option>
                                    <option value="2nd Semester">3rd Semester</option>
                                </select>
                                {/* : null
                                } */}
                            </div>
                        </div>
                        <div>
                            <input 
                            onClick={()=>handleGameClick3(aqlp)}
                            value={aqlp}
                            type="checkbox" class="form-check-input mr-3 mt-2" id="aql" />
                            <label class="form-check-label lead" for="aql">Advanced Quran Learning Program (AQLP)</label>
                            <div className="mt-2">
                                {/* {
                                aqlp ? */}
                                <input disabled={!aqlp} name="sura" type="text" class="form-control" placeholder="SURA : " required />
                                {/* : null
                                } */}
                            </div>
                        </div>
                    </div>
                    <div className="col-md-6">
                        <div className="">
                            <input 
                            onClick={()=>handleGameClick4(hq)}
                            value={hq}
                            type="checkbox" class="form-check-input mr-3 mt-2" id="hq" />
                            <label class="form-check-label lead" for="hq">Hifzul Quran</label>
                            <div className="mt-2">
                                {/* {
                                hq ? */}
                                <select disabled={!hq} name="quran" className="form-control mb-3" id="Select-Semestar" required>
                                    <option value="" disable>Select-Level</option>
                                    <option value="Level-One">Level One</option>
                                    <option value="Level-Two">Level Two</option>
                                    <option value="Level-Tree">Level Three</option>
                                </select>
                                {/* : null
                                } */}
                            </div>
                        </div>
                        <div className="my-3">
                            <input 
                            onClick={()=>handleGameClick5(qrrp)}
                            value={qrrp}
                            type="checkbox" className="form-check-input mr-3 mt-2" id="qrrp" />
                            <label class="form-check-label lead" for="qrrp">Quran Reading & Reciting Program</label>
                            <div className="mt-2">
                                {/* {
                                qrrp ? */}
                                <select disabled={!qrrp} name="reading" className="form-control mb-3" id="Select-Semestar" required>
                                    <option value="" disable>Select-Semestar</option>
                                    <option value="1st Semester">1st Semester</option>
                                    <option value="2nd Semester">2nd Semester</option>
                                    <option value="2nd Semester">3rd Semester</option>
                                </select>
                                {/* : null
                                } */}
                            </div>
                        </div>
                    </div>
                </div>
                
                
                <div className="col-md-4 col-sm-6 mt-3 pt-3">
                    <button type="submit" className="btn btn-success ml-3 float-right" disabled={!(alqg || fqg || aqlp || hq || qrrp)}>Apply</button>
                    <button type="reset" className="btn btn-warning float-right">Cancel</button>
                </div>
            </form>
        </div>
    )
}

export default Form
